import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import BlogLandingPage from '../BlogLanding';
import EventsWebinarLanding from '../EventsWebinarLanding';
import PodcastLanding from '../PodcastLanding';
import ResearchLanding from '../ResearchLanding';

export default function CategoryTagLandingPage({ data, basePath, location }) {
    const blogLandingData = data?.contentfulBlogLandingPage;
    const eventLandingData = data?.contentfulEventsAndWebinarLandingPage;
    const podcastLandingData = data?.contentfulPodcastLandingPage;
    const researchLandingData = data?.contentfulResearchLandingPage;
    const blogContentData = data?.allContentfulBlogs?.nodes;
    const eventContentData = data?.allContentfulEventsAndWebinar?.nodes;
    const podcastContentData = data?.allContentfulPodcast?.nodes;
    const researchContentData = data?.allContentfulResearch?.nodes;
    const urlParams = new URLSearchParams(location.search)
    const blogs = blogContentData.filter((blog) => {
        if (urlParams.has('category')) {
            return blog?.blogContent?.categoryList?.find(
                (category) => category.contentful_id === urlParams.get('category')
            );
        } else if (urlParams.has('tag')) {
            return blog?.blogContent?.tagList?.find(
                (tag) => tag.contentful_id === urlParams.get('tag')
            );
        } else {
            return blog;
        }
    });

    const eventsWebinars = eventContentData.filter((events) => {
        if (urlParams.has('category')) {
            return events?.categoryList?.find(
                (category) => category.contentful_id === urlParams.get('category')
            );
        } else if (urlParams.has('tag')) {
            return events?.tagList?.find((tag) => tag.contentful_id === urlParams.get('tag'));
        } else {
            return events;
        }
    });

    const researchs = researchContentData.filter((research) => {
        if (urlParams.has('category')) {
            return research?.categoryList?.find(
                (category) => category.contentful_id === urlParams.get('category')
            );
        } else if (urlParams.has('tag')) {
            return research?.tagList?.find((tag) => tag.contentful_id === urlParams.get('tag'));
        } else {
            return research;
        }
    });

    const podcasts = podcastContentData.filter((podcast) => {
        if (urlParams.has('category')) {
            return podcast?.categoryList?.find(
                (category) => category.contentful_id === urlParams.get('category')
            );
        } else if (urlParams.has('tag')) {
            return podcast?.tagList?.find(
                (tag) => tag.contentful_id === urlParams.get('tag')
            );
        } else {
            return podcast;
        }
    });
    return (
        <>
            <Grid>
                {blogs.length ? (
                    <>
                        <BlogLandingPage
                            landingData={blogLandingData}
                            basePath={basePath}
                            location={location}
                            contentData={blogs}
                        />
                    </>
                ) : (
                    <></>
                )}
                {eventsWebinars.length ? (
                    <>
                        <EventsWebinarLanding
                            landingData={eventLandingData}
                            contentData={eventsWebinars}
                            basePath={basePath}
                            location={location}
                        />
                    </>
                ) : (
                    <></>
                )}

                {podcasts.length ? (
                    <>
                        <PodcastLanding
                            podcastLanding={podcastLandingData}
                            podcastContent={podcasts}
                            basePath={basePath}
                            location={location}
                        />
                    </>
                ) : (
                    <></>
                )}

                {researchs.length ? (
                    <>
                        <ResearchLanding
                            landingData={researchLandingData}
                            contentData={researchs}
                            basePath={basePath}
                            location={location}
                        />
                    </>
                ) : (
                    <></>
                )}

            </Grid>
        </>
    );
}
