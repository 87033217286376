import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import CategoryTagLandingPage from '../components/CategoryTagCommonLanding';


export default function categorytaglandingPage({ location, pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  //console.log("contextPage-",pageContext);
  /*console.log("data-",data);*/
  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulCategoryTagsLandingPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url + '?fm=webp&q=100'}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <CategoryTagLandingPage  
        data={data}
        basePath={pageContext.basePath}
        location={location}
        
        />
        
      </Layout>
    </>
  );
}

export const query = graphql`
query
CommonLandingQuery($contentful_id: String, $locale: String) {
  contentfulCategoryTagsLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }){
    entryTitle
    seoEntry {
      pageTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
      metaRobotsTag
      ogType
      ogSiteName
      defaultOgLocale
      altimageTag
      ogImage {
        file {
          url
        }
      }
    }
    slug
  }
contentfulBlogLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
  entryTitle
  header
  loadMoreButton
  node_locale
  dotMatrixBackgroundImage {
    file {
      url
    }
  }
  capsulesBackgroundImage {
    file {
      url
    }
  }
  contentful_id
  leftDonutBackgroundImage {
    file {
      url
    }
  }
}
allContentfulBlogs(filter:{node_locale: {eq: $locale}}) {
  nodes {
    node_locale
    id
    entryTitle
    contentful_id
    blogSlug
    featured
    news
    pageVisible
    blogContent {
      ... on ContentfulShortBlog {
        id
        dontDisplayPublishedDateInBlogLandingTile
        dateOfPublication(locale: "US")
        shortBlogTitle
        thumbnailImage {
          description
          file {
            url
          }
        }
        description {
          raw
        }
        categoryList {
          categoryName
          contentful_id
          entryTitle
        }
        tagList {
          contentful_id
          entryTitle
          tagName
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulLongBlog {
        id
        longBlogTitle
        dontDisplayPublishedDateInBlogLandingTile
        dateOfPublication(locale: "US")
        thumbnailImage {
          description
          file {
            url
          }
        }
        description {
          raw
        }
        categoryList {
          categoryName
          contentful_id
          entryTitle
        }
        tagList {
          contentful_id
          entryTitle
          tagName
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPodcastBlog {
        id
        dontDisplayPublishedDateInBlogLandingTile
        dateOfPublication(locale: "US")
        podcastBlogTitle
        description {
          raw
        }
        podcastBlogTileImage {
          description
          file {
            url
          }
          title
        }
        categoryList {
          categoryName
          contentful_id
          entryTitle
        }
        tagList {
          contentful_id
          entryTitle
          tagName
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
}

contentfulEventsAndWebinarLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale } ) {
  header
  entryTitle
  contentful_id
  node_locale
  capsulesBackgroundImage {
    file {
      url
    }
    description
  }
  dotMatrixBackgroundImage {
    file {
      url
    }
    description
  }
  header
  leftDonutBackgroundImage {
    file {
      url
    }
    description
  }
  loadMoreButton
}
allContentfulEventsAndWebinar(filter: { node_locale: { eq: $locale } }) {
  nodes {
    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
    pageVisible
    contentful_id
    node_locale
    imageOrVideo
    buttonLabel
    dateOfEvent
    dateOfPublishing
    eventsOrWebinarTitle
    shortSummary {
      raw
    }
    video {
      videoId
      videoType
    }
    slug
    eventThumbnailImage {
      file {
        url
      }
      description
    }
  }
}
contentfulPodcastLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
  header
  entryTitle
  contentful_id
  leftDonutBackgroundImage {
    file {
      url
    }
  }
  dotMatrixBackgroundImage {
    file {
      url
    }
  }
  capsulesBackgroundImage {
    file {
      url
    }
  }
  loadMoreButtonLabel
}
allContentfulPodcast(filter: { node_locale: { eq: $locale } }) {
  nodes {
    contentful_id
    entryTitle
    node_locale
     podcastSlug
    podcastTitle
    pageVisible
    publishedDate(locale: "US")
    thumbnailImage {
      description
      file {
        url
      }
    }
    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
  }
}
contentfulResearchLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
  header
  entryTitle
  contentful_id
  node_locale
  capsulesBackgroundImage {
    description
    file {
      url
    }
  }
  dotMatrixBackgroundImage {
    description
    file {
      url
    }
  }
  leftDonutBackgroundImage {
    description
    file {
      url
    }
  }
  loadMoreButton
  header
}
allContentfulResearch (filter: {node_locale: {eq: $locale}}){
  nodes {
    node_locale
    id
    entryTitle
    contentful_id
    researchSlug
    researchTitle
    pageVisible
    thumbnailImage {
      description
      file {
        url
      }
    }
    dateOfPublishing
    resourceType
    shortDescription {
      raw
    }
    thumbnailImage {
      description
      file {
        url
      }
    }
    document {
      file {
        url
      }
    }
    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
}
}

`;
